var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.feeds.length)?_c('div',[_c('om-table',{staticClass:"feed-table",attrs:{"items":_vm.feeds,"columns":_vm.columns,"columnClasses":_vm.columnClasses,"allItemsCount":_vm.feeds.length,"selectable":false,"show-header":true,"paginate":false},scopedSlots:_vm._u([{key:"domain",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(rowData.row.domain))])]}},{key:"type",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(rowData.row.type))])]}},{key:"status",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"row-1"},[(rowData.row.error)?_c('om-tooltip',[_c('span',[_vm._v(_vm._s(rowData.row.error.message))]),_c('template',{slot:"trigger"},[_c('span',{staticClass:"cursor-pointer text-danger"},[_vm._v(_vm._s(_vm.$t(("feeds.table.states." + (rowData.row.status.state)))))])])],2):_c('span',[_vm._v(_vm._s(_vm.$t(("feeds.table.states." + (rowData.row.status.state)))))])],1),(rowData.row.synced)?_c('div',{staticClass:"row-2"},[_c('span',[_vm._v("Last sync: "+_vm._s(rowData.row.synced))])]):_vm._e(),(rowData.row.status.nofXmlItem)?_c('div',{staticClass:"row-2",attrs:{"title":rowData.row.status.extraInfo}},[_c('span',[_vm._v(_vm._s(rowData.row.status.nrOfProcessed))]),_c('span',{staticClass:"pl-1"},[_vm._v("/")]),_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(rowData.row.status.nofXmlItem))]),(rowData.row.status.nofError)?_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$t('feeds.table.errors', { count: rowData.row.status.nofError })))]):_vm._e()]):_vm._e()]}},{key:"url",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showUrlModal(rowData.row.url)}}},[_c('span',[_vm._v(_vm._s(_vm.urlToDisplay(rowData.row.url)))])])]}},{key:"sync",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('om-checkbox',{attrs:{"id":"autoSyncCheckbox","name":"auto-sync-checkbox","autocomplete":"off"},on:{"input":function($event){return _vm.setAutoSyncSetting(rowData.row, $event)}},model:{value:(rowData.row.autoSync),callback:function ($$v) {_vm.$set(rowData.row, "autoSync", $$v)},expression:"rowData.row.autoSync"}})],1)]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('om-button',{staticClass:"mr-2",attrs:{"secondary":"","small":"","icon":"sync","icon-only":""},on:{"click":function($event){return _vm.syncFeed(rowData.row.id)}}}),_c('om-button',{staticClass:"mr-2",attrs:{"secondary":"","small":"","icon":"trash-alt","icon-only":""},on:{"click":function($event){return _vm.deleteFeed(rowData.row.id)}}})],1)]}}],null,false,2606654779)}),_c('url-modal'),_c('sync-modal'),_c('delete-modal')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }