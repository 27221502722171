<template lang="pug">
div(v-if="feeds.length")
  om-table.feed-table(
    :items="feeds"
    :columns="columns"
    :columnClasses="columnClasses"
    :allItemsCount="feeds.length"
    :selectable="false"
    :show-header="true"
    :paginate="false"
  )
    template(slot="domain" slot-scope="{ rowData }")
      span {{ rowData.row.domain }}

    template(slot="type" slot-scope="{ rowData }")
      span {{ rowData.row.type }}

    template(slot="status" slot-scope="{ rowData }")
      .row-1
        om-tooltip(v-if="rowData.row.error")
          span {{ rowData.row.error.message }}
          template(slot="trigger")
            span.cursor-pointer.text-danger {{ $t(`feeds.table.states.${rowData.row.status.state}`) }}
        span(v-else) {{ $t(`feeds.table.states.${rowData.row.status.state}`) }}
      .row-2(v-if="rowData.row.synced")
        span Last sync: {{ rowData.row.synced }}
      .row-2(v-if="rowData.row.status.nofXmlItem" :title="rowData.row.status.extraInfo")
        span {{ rowData.row.status.nrOfProcessed }}
        span.pl-1 /
        span.pl-1 {{ rowData.row.status.nofXmlItem }}
        span.pl-2(v-if="rowData.row.status.nofError") {{ $t('feeds.table.errors', { count: rowData.row.status.nofError }) }}

    template(slot="url" slot-scope="{ rowData }")
      .cursor-pointer(@click="showUrlModal(rowData.row.url)")
        span {{ urlToDisplay(rowData.row.url) }}

    template(slot="sync" slot-scope="{ rowData }")
      .d-flex.justify-content-end
        om-checkbox#autoSyncCheckbox(
          v-model="rowData.row.autoSync"
          name="auto-sync-checkbox"
          autocomplete="off"
          @input="setAutoSyncSetting(rowData.row, $event)"
        )

    template(slot="actions" slot-scope="{ rowData }")
      .d-flex.justify-content-end
        om-button.mr-2(secondary small icon="sync" @click="syncFeed(rowData.row.id)" icon-only)
        om-button.mr-2(
          secondary
          small
          icon="trash-alt"
          @click="deleteFeed(rowData.row.id)"
          icon-only
        )

  url-modal
  sync-modal
  delete-modal
</template>
<script>
  import dateFormat from '@/mixins/dateFormat';
  import FETCH_DATA_SOURCES from '@/graphql/FetchDataSources.gql';
  import SET_PRODUCT_FEED_SETTINGS from '@/graphql/SetProductFeedSettings.gql';

  const FEED_STATES = {
    WAITING: 'waiting',
    RUNNING: 'running',
    FINISHED: 'finished',
    ERROR: 'error',
  };

  const DATE_FORMAT = 'LLL';

  export default {
    components: {
      UrlModal: () => import('@/components/Feeds/FeedUrlModal.vue'),
      DeleteModal: () => import('@/components/Feeds/FeedDeleteModal.vue'),
      SyncModal: () => import('@/components/Feeds/FeedSyncModal.vue'),
    },
    mixins: [dateFormat],
    data: () => ({
      columns: [
        { header: 'domain', key: 'domain', sortable: false },
        { header: 'type', key: 'type', sortable: false },
        { header: 'url', key: 'url', sortable: false },
        { header: 'status', key: 'status', sortable: false },
        { header: 'Auto Sync', key: 'sync', sortable: false },
        { header: 'actions', key: 'actions', sortable: false },
      ],
      columnClasses: {
        domain: 'text-left',
        type: 'text-left',
        url: 'text-left',
        status: 'text-left',
        actions: 'text-right',
      },
      feedList: [],
    }),
    computed: {
      feeds() {
        return this.feedList.map((feedData) => ({
          id: feedData._id,
          domain: feedData.domain?.name,
          type: feedData.type,
          url: feedData.properties?.url,
          autoSync: feedData.properties?.autoSync ?? false,
          status: this.buildStatusData(feedData),
          error: feedData.properties?.error,
          synced: feedData.properties?.syncFinishedAt
            ? this.formatDate(feedData.properties.syncFinishedAt, DATE_FORMAT)
            : '',
        }));
      },
    },
    mounted() {
      this.fetchFeedListData();
      this.$bus.$on('reloadFeedTable', () => {
        this.fetchFeedListData();
      });
    },
    methods: {
      async setAutoSyncSetting(rowData, autoSync) {
        try {
          await this.$apollo.mutate({
            mutation: SET_PRODUCT_FEED_SETTINGS,
            variables: {
              id: rowData.id,
              settings: {
                autoSync,
              },
            },
          });
        } catch (err) {
          console.error('Caught error during auto sync update', {
            msg: err.message,
            stack: err.stack,
          });
        }

        await this.fetchFeedListData();
      },
      async fetchFeedListData() {
        try {
          const response = await this.$apollo.query({
            query: FETCH_DATA_SOURCES,
          });

          this.feedList = response?.data?.result || [];
        } catch (err) {
          console.error('Caught error during data source fetching', {
            msg: err.message,
            stack: err.stack,
          });
        }
      },
      syncFeed(id) {
        this.showSyncModal(id);
      },
      deleteFeed(id) {
        this.showDeleteModal(id);
      },
      buildStatusData(feedData) {
        const state = feedData.properties?.error
          ? FEED_STATES.ERROR
          : feedData.properties?.syncFinishedAt
          ? FEED_STATES.FINISHED
          : feedData.properties?.syncStartedAt
          ? FEED_STATES.RUNNING
          : FEED_STATES.WAITING;
        return {
          state,
          nrOfProcessed: feedData.properties?.status?.syncCount,
          nofXmlItem: feedData.properties?.status?.parsedCount,
          nofError: feedData.properties?.status?.errorCount,
          extraInfo: [FEED_STATES.RUNNING].includes(state)
            ? `Started at: ${this.formatDate(feedData.properties?.syncStartedAt, DATE_FORMAT)}`
            : '',
        };
      },
      urlToDisplay(url) {
        const maxLength = 60;

        return url.length < maxLength
          ? url
          : `${url.slice(0, maxLength - maxLength / 2)} ... ${url.slice(-(maxLength / 2))}`;
      },
      showUrlModal(url) {
        this.$modal.show('feed-url-modal', { url });
      },
      showSyncModal(id) {
        this.$modal.show('feed-sync-modal', { id });
      },
      showDeleteModal(id) {
        this.$modal.show('feed-delete-modal', { id });
      },
    },
  };
</script>

<style lang="sass" scoped>
  .feed-table
    ::v-deep.brand-table

      .thead
        margin: 0

      .th,
      .td
        padding: 0 5px !important

      .thead
        .tr
          .th
            span
              padding: 20px 0
              text-transform: capitalize
              font-weight: 600
              font-size: 120%

          .text-left
            display: flex
            justify-content: flex-start
            text-align: left !important
          .text-right
            display: flex
            justify-content: flex-end !important

    ::v-deep.brand-table
      .tbody
        .brand-table-tr
          padding: 15px 0
          margin: 0
          min-height: 0

          .td
            font-weight: 400
</style>
